import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled from "styled-components";
import SEO from "../components/organisms/seo";
import ArticlePageModules from "../components/organisms/articlePageModules";
import Tags from "../components/molecules/tags";

const Post = ({ data }) => {
  const { title, tags, content } = data.post;

  return (
    <div className="spacing-top-big">
      <SEO siteTitle={title} />
      <StyledPost>
        <h3 className="bold-small">{title}</h3>
        <Tags tags={tags.options} />
        <ArticlePageModules
          isJournalLanding
          showEntryImageCopy
          showImageGallery
          content={content}
        />
      </StyledPost>
    </div>
  );
};

const StyledPost = styled.section`
  .bold-small {
    padding-bottom: var(--spacing-S);
  }
`;

export const query = graphql`
  query($slug: String!) {
    post: sanityJournalPost(slug: { current: { eq: $slug } }) {
      title
      tags {
        options
      }
      content {
        ... on SanityEntryImageCopy {
          image {
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            alt
          }
          _rawCopy
        }
        ... on SanityImageGallery {
          images {
            alt
            portrait
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        ... on SanityMediaCopy {
          type
          media {
            ... on SanityMainImage {
              alt
              image {
                asset {
                  fluid(maxWidth: 3600) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            ... on SanityVideo {
              videoFile {
                asset {
                  url
                }
              }
            }
          }
          overline
          _rawCopy
        }
        ... on SanityCollage {
          type
          big {
            ... on SanityMainImage {
              alt
              image {
                asset {
                  fluid(maxWidth: 3600) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            ... on SanityVideo {
              videoFile {
                asset {
                  url
                }
              }
            }
          }
          small {
            ... on SanityMainImage {
              alt
              image {
                asset {
                  fluid(maxWidth: 3600) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            ... on SanityVideo {
              videoFile {
                asset {
                  url
                }
              }
            }
          }
          _rawCopy
        }
        ... on SanityVideo {
          videoFile {
            asset {
              url
            }
          }
          previewImage {
            asset {
              url
            }
          }
          title
          minutes
          seconds
        }
        ... on SanityMainImage {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanityPullQuote {
          quote
          _rawAuthor
        }
        ... on SanityCopy {
          _rawCopyLeft
          _rawCopyRight
        }
        ... on SanityFacts {
          title
          fact {
            overline
            _rawCopy
          }
        }
        ... on SanityMail {
          title
        }
        ... on SanityDonateCta {
          ctaText
        }
        ... on SanityJournalCta {
          journalImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          overline
          headline
          tags {
            options
          }
          copy
          slug
        }
        ... on SanityCopyCta {
          overline
          headline
          link {
            ctaText
            pageLink
          }
        }
        ... on SanityImageCta {
          image {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          overline
          copy
          link {
            ctaText
            pageLink
          }
        }
      }
    }
  }
`;

Post.propTypes = {
  data: PropTypes.object,
};

export default Post;
